.carousel.carousel-slider {
    margin-bottom: 20px;
}
.carousel .carousel-status {
    font-size: 12px;
    top: 10px;
    right: 16px;
    padding: 0;
}
.carousel .thumbs-wrapper {
    margin: 0;
}
.carousel-slider img {
    border-radius: 12px;
    height: 486px;
    object-fit: cover;
}
.thumbs-wrapper .thumb img {
    border-radius: 6px;
    height: 96px;
    object-fit: cover;
}
.thumbs-wrapper .thumb {
    border: none;
    padding: 0;
    padding-right: 10px;
    margin-right: 0;
    position: relative;
}
.thumbs-wrapper .thumb:before {
    border-radius: 6px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 10px;
    bottom: 0;
    background-color: #000;
}
.thumbs-wrapper .thumb img {
    position: relative;
    opacity: 0.4;
    z-index: 1;
}
.thumbs-wrapper .thumb.selected,
.thumbs-wrapper .thumb:hover {
    border: none;
}
.thumbs-wrapper .thumb.selected img,
.thumbs-wrapper .thumb:hover img {
    opacity: 1;
}
.thumbs-wrapper .control-next.control-arrow {
    right: 8px;
}
.thumbs-wrapper .control-prev.control-arrow {
    left: 8px;
}
.carousel.carousel-slider .control-arrow {
    padding-left: 20px;
    padding-right: 20px;
}